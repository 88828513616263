import { palette } from './palette';

export const homeStyle = {
  /* Login - Styles */
  '.login-root': {
    '& .hero-shaped__wrapper': {
      maxWidth: 'unset',
      minHeight: 'unset !important',

      '& .hero-shaped__left-side': {
        flex: '0 0 60%',
        maxWidth: '60%',

        '@media(min-width: 900px) and (max-width: 1200px)': {
          flex: '0 0 50%',
          maxWidth: '50%',
        },

        '& .hero-shaped__image': {
          width: '60vw',

          '@media(min-width: 900px) and (max-width: 1200px)': {
            width: '50vw',
          },
        },
      },
    },
  },

  '.blueprintSmiles-container': {
    '& .normal-text': {
      fontWeight: 300,
    },

    /* Hero banner */
    '& .savings-hero-section, .checkout-hero-section, .plan-selection-section, .faq-hero-section, .dashboard-hero-section, .find-provider-section, .hipaa-hero-section, .privacy-hero-section, .terms-hero-section':
      {
        backgroundColor: palette.primary.main,
        '& .hero-header-title': {
          color: palette.text.highlight,
        },
        '& .hero-header-subtitle': {
          color: palette.text.highlight,
        },
      },

    /* App main - styles */
    '& .app-main-content': {
      paddingTop: 0,
    },

    /* Hero Section - Styles */
    '& .home-hero-wrapper': {
      backgroundColor: palette.text.alternate,

      '& .hero-background': {
        objectFit: 'cover',

        '@media screen and (max-width: 900px)': {
          height: '340px',
        },
      },
      '& .hero-section': {
        display: 'flex',
        alignItems: 'center',
        maxHeight: '500px',

        '& .MuiSelect-outlined': {
          background: palette.primary.main,
          color: palette.text.highlight,
          borderRadius: '10px',
        },
      },

      '& .home-hero-title': {
        maxWidth: 600,
        color: '#035A96',

        '@media(min-width: 900px)': {
          lineHeight: 1.375,
        },

        '@media screen and (max-width: 900px)': {
          paddingTop: '32px',
          '& .section-header__subtitle': {
            color: palette.text.secondary
          }
        }
      },
      '& .home-hero-subtitle': {
        fontSize: '2.0243rem',
        color: palette.text.highlight,
      },
    },

    /* Benefits - Styles */
    '& .benefits-wrapper': {
      '& section-header__title': {
        weight: '600',
      },
    },

    /* Pricing & Benefits / Subscription - Styles */
    '& .subscription-wrapper': {
      color: `${palette.text.highlight} !important`,

      '& .MuiSwitch-thumb': {
        background: palette.text.alternate,
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: palette.text.highlight,
      },
      '& .subscription-bg': {
        height: '100%',
        background: palette.primary.main,
      },
      '& .section-header__title': {
        color: palette.text.highlight,
      },
      '& .section-header__subtitle': {
        color: palette.text.highlight,
      },
      '& .select-plan-button': {
        color: palette.text.primary,
        background: palette.text.highlight,
      },
      '& #pricing-benefit-section': {
        '& .planCard': {
          '&:hover': {
            backgroundColor: palette.text.highlight,
            color: palette.text.primary,
            opacity: 0.9,
          },
          '& .highlight-divider': {
            backgroundColor: palette.text.disabled,
          },
          '& .MuiButton-containedSecondary': {
            '&:hover': {
              opacity: 1
            },
          },
        },
        '& .subscription-section': {
          '& .section-header': {
            maxWidth: 720,

            '@media(min-width: 900px)': {
              margin: '0px auto 32px',
            },
          },
          '& .plans-loader': {
            color: palette.text.highlight,
          },
        },
        '& .savings-summary-link': {
          textDecoration: 'underline',
          cursor: 'pointer',
          color: palette.text.highlight,
        },
      },
    },

    /* How it works - Styles */
    '& .howitworks-section': {
      background: 'none',

      '& .howitworks-wrapper': {
        padding: 0,
      },

      '& .section': {
        padding: 0,
      },

      '& .howitworks-wrapper-left': {
        padding: '40px',
      },

      '& .makeStyles-root-54': {
        padding: 0,
      },

      '& .how-cover-image': {
        height: '100%',
        width: '100%',
        backgroundSize: 'cover',
      },

      '& .step-counter': {
        width: 60,
        height: 60,
        background: palette.primary.light,
        color: palette.text.primary,
        borderRadius: 8,
      },

      '& .step-started-button': {
        background: palette.text.alternate,
        color: palette.text.highlight,
        marginTop: 32,
      },

      '& .working-steps-container:not(:last-child)': {
        paddingBottom: 30,
      },

      '@media(min-width: 900px)': {
        '& .howitworks-wrapper-left': {
          paddingLeft: '100px',
        },
      },
    },

    /* Testimonial - Styles */
    '& .testimonial-wrapper': {
      background: palette.background.light,

      '@media(min-width: 900px)': {
        padding: 72,
      },

      '& .testimonial-name, & .testimonial-location, & .testimonial-message': {
        color: palette.text.alternate,
      },

      '& .testimonialSection': {
        padding: '40px 32px',

        '@media(min-width: 900px)': {
          padding: '40px 16px',
        },
      },
    },

    /* Savings Summary */
    '& .savings-wrapper': {
      '& .section-header': {
        '& .section-header__title': {
          color: palette.text.primary,
        },
        '& .section-header__subtitle': {
          color: palette.text.primary,
        },
      },
      '& .service-plan-table .savings-subheader': {
        backgroundColor: palette.background.main,
        fontWeight: 600,
      },
      '& .savings-contact-card': {
        backgroundColor: palette.background.footer,
      },
      '& .savings-topheader': {
        color: `${palette.text.highlight} !important`,
      },
      '& .savings-header': {
        color: palette.text.primary,
      },
      '& .MuiTypography-root': {
        color: palette.text.highlight,
      },
    },

    /* Static hero banner */
    '& .home-static-hero-wrapper': {
      '& .hero-static-bg': {
        backgroundColor: palette.background.footer,
      },
      '& .section-header__title': {
        color: palette.text.highlight,
      },
      '& .section-header__subtitle': {
        color: palette.text.highlight,
      },
    },

    /* Book Appointment */
    '& .morning-image, & .noon-image, & .evening-image': {
      fill: `${palette.primary.main} !important`,
    },

    /* Create Subscriber - Checkout flow container */
    '& .checkout-flow-container': {
      '& .provider-details': {
        border: `1px solid ${palette.text.border}`,
      },
    },

    /* Create Subscriber - Choose plan */
    '& .plan-selection-container': {
      '& .plans-wrapper': {
        '& .planCard': {
          '&:hover': {
            color: palette.text.primary,
          },
          '& .highlight-divider': {
            backgroundColor: palette.text.highlight,
          },
        },
      },
    },

    /* Create Subscriber - Checkout */
    '& .checkout-outer-container': {
      '& .payment-title-root': {
        marginTop: 0,
      },
      '& .promo-spinner': {
        backgroundColor: 'unset',
      },
    },

    /* Contact Card - FAQ, Success Page */
    '& .faq-contact-wrapper': {
      background: palette.background.footer,
      '& .MuiTypography-root': {
        color: palette.text.highlight,
      },
    },

    /* Employer Style */
    '& .employerRootContainer': {
      '& .home-hero-wrapper': {
        background: palette.text.highlight,
        '& .hero-section': {
          display: 'flex',
          alignItems: 'center',
          maxHeight: '600px',
        },
        '& .home-hero-title': {
          maxWidth: '100%',
            fontSize: 44
        },
        '& .home-hero-subtitle': {
            maxWidth: 600
        },
        '@media(max-width:900px)': {
          '& .home-hero-subtitle': {
            color: palette.text.secondary,
            fontSize: 20,
          },
          '& .home-hero-title': {
            fontSize: 38,
              paddingTop: 0
            }
          
          }
      },
      '@media(max-width:900px)': {
        '& .definition-variant-2': {
          padding: '32px 8px'
        }
      },
      '& #pricing-benefit-section': {
        '& .customGetStarted': {
          background: palette.background.light,
          color: palette.primary.main
        },
        '@media(min-width:900px)': {
          '& .customButtonWrapper': {
            width: 412,
            marginLeft: 0
          }
        }
      },
      '& .benefits-wrapper': {
        '& .customIcon': {
          display: 'flex',
          justifyContent: 'right',
          width: 56,
          height: 56,
          flexBasis: '20%'
        },
        '@media(min-width:900px)': {
          '& .section-header': {
            paddingBottom: 60
          },
          '& .definition-service-description': {
            width: 280,
            fontSize: 16
          },
        },
        '@media(max-width:900px)': {
          '& .section-header__title': {
          fontSize: 30
        }}
      },
      '& .primary-static-button': {
        background: palette.text.highlight,
        color: palette.text.primary
      }
    },

    /* Footer Disclosure */
    '& .footer-wrapper, & .footer-wrapper a': {
      color: palette.text.highlight,
    },

    /* Dashboard Page - Appointment Header */
    '& .MuiTableRow-root .MuiTableCell-head': {
      color: palette.text.highlight,
    },
  },
};