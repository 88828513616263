export const palette = {
  alternate: {
    main: '#F9F6FF',
    light: '#F9F8F8',
    dark: '#E5EAF4',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light',
  primary: {
    main: '#0F75BC',
    contrastText: '#FFF',
    light: '#0F75BC1e',
  },
  secondary: {
    main: '#0F75BC1e',
    contrastText: '#0A5285',
  },
  text: {
    primary: '#092644',
    secondary: '#64748B',
    alternate: '#23B0E7',
    disabled: '#CCC',
    highlight: '#FFF',
    success: '#1CA179',
    border: '#E5EAF4',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    main: '#0F75BC1e',
    paper: '#FFF',
    default: '#FFF',
    footer: '#4E4D4D',
    level2: '#f7f8fb',
    level1: '#FFF',
    tableHead: '#0F75BC',
    tableSubhead: '#0F75BC1e',
    tableAlternate: '#F8F8F8',
    alternate: '#E3F1FD',
    success: '#DBEFDC',
    error: '#FFEAEA',
    secondary: '#F8D859',
    light: '#F6F6F699'
  },
  verticalBar: {
    used: '#7BD6D0',
    unused: '#7BD6D01a',
    unlimited: '#C0C0C0',
  }
};
